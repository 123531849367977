import { createContext, useState, useContext } from "react";

export const CheckOutContext = createContext('')

const CheckOutProvider = ({ children }) => {
    const [personalInfo, setPersonalInfo] = useState({
        Avenue: '',
        Block: '',
        DeliveryType: '',
        FloorApartment: '',
        House: '',
        State: '',
        Street: '',
        TotalOrderPrice: '',
        Notes: '',
        ShippingCost: '',
        Discount: '',
        FullName: '',
        Phone: '',
        Email: '',
        Country: '',
    })

    function handleForm(e) {
        setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value })
    }

   

    return (
        <CheckOutContext.Provider value={{ personalInfo, setPersonalInfo, handleForm }}>
            {children}
        </CheckOutContext.Provider>
    )
}

export default CheckOutProvider

// custom hooks
export const useCheckOut = () => {

    return useContext(CheckOutContext)
}