import { LocalContext } from '../context/LocalContext';
import { useContext, useState } from 'react';
import '../css/card1.css'
import { NavLink } from 'react-router-dom';
import { useCart } from '../context/CartProvider';
import ToastContext from '../context/ToastProvider';


import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

export default function Card1(props) {
  const { showHideToast } = useContext(ToastContext);
  const { locale, setLocale } = useContext(LocalContext);
  const theme = useTheme();
  const { id, Name_en, Name_ar, sale_price, price, images } = props
  const image = images.split(',')[0];

  // console.log('props', props)

  const [amount, setAmount] = useState(1);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    addToCart(id, amount, props);
    showHideToast("added successfully");
  };

  return (
    <div dir={locale === "en" ? "ltr" : "rtl"} className={[locale === "en" ? "ltr" : "rtl", "card1"].join(" ")}>
      <NavLink to={`/product/${id}`}>
        <img decoding="async" src={image} alt="" />
        <div className="content">
          <h3 style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>{locale === 'en' ? Name_en : Name_ar}</h3>
          <Stack direction="row" spacing={1} alignItems="center">
            <span style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} className='sale-price'>{sale_price} {locale === "en" ? "KD" : "د.ك"}</span>
            <span className="old-price">{price} {locale === "en" ? "KD" : "د.ك"}</span>
          </Stack>
        </div>
      </NavLink>
      <div className="info">
        <Button
          onClick={handleAddToCart}
          size='small'
          sx={{ fontWeight: 'bold', color: theme.palette.mode === 'dark' ? 'white' : 'black', fontSize: '12px', borderColor: theme.palette.mode === 'dark' ? 'white' : 'black', '&:hover': { borderColor: theme.palette.mode === 'dark' ? 'white' : 'black' } }}
          variant="outlined"
        >
          + Add
        </Button>
      </div>
    </div>
  )
}
