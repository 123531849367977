import { useContext } from 'react';
import { LocalContext } from '../context/LocalContext';

export default function FormatPrice({price, sale_price}) {
    const { locale, setLocale } = useContext(LocalContext);
    const formattedPrice = sale_price ? sale_price : price ;
    const localeString = locale === "en" ? "en-KW" : "ar-KW";
    
    return Intl.NumberFormat(localeString, {
        style: "currency",
        currency: "KWD",
        maximumFractionDigits: 0,
      }).format(formattedPrice);
}
