import React from 'react'
import { useParams } from 'react-router-dom'
import { useEffect, useContext } from 'react'
import { LocalContext } from '../context/LocalContext'
import { baseUrl, PRODUCTS } from '../api/Api'
import { useProduct } from '../context/ProductProvider'
import '../css/oneproduct.css'
import Myimage from '../components/Myimage'
import { useTranslation } from 'react-i18next';
import FormatPrice from '../helpers/FormatPrice'
import AddToCart from '../components/AddToCart'

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import { Box, Stack, Typography } from "@mui/material";
import Rating from '@mui/material/Rating';
import { useTheme } from '@mui/material/styles';

const oneProductApi = `${baseUrl}${PRODUCTS}`

export default function OneProduct() {
  const { getSingleProduct, isSingleLoading, singleProduct } = useProduct();
  const { product } = singleProduct || {};
  const { locale, setLocale } = useContext(LocalContext);
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // const { images, Description_ar, Description_en, Name_ar, Name_en, price, sale_price, stock, category: {Name_ar: category_ar, Name_en: category_en}  } = product || {};
  // console.log('product', product)
  const { id } = useParams()

  useEffect(() => {
    getSingleProduct(`${oneProductApi}/${id}`)
  }, [])

  if (isSingleLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )
  }

  return (
    <div dir={locale === "en" ? "ltr" : "rtl"} className={[locale === "en" ? "ltr" : "rtl", "oneProduct"].join(" ")} style={{ backgroundColor: theme.palette.mode === 'dark' ? '#0f0f0f' : '#f1f4fb' }}>
      <Container maxWidth="lg">
        <Grid className={[theme.palette.mode === 'dark' ? 'dark' : 'light', "first-grid"].join(" ")} container spacing={2}>
          <Grid xs={12} md={6}>
            <Myimage images={product?.images} />
          </Grid>
          <Grid className="white-container" xs={12} md={6}>
            <div>
              <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{padding: "35px 10px 0 0"}}>
                <div>
                  <h2 className="category-name" style={{ margin: '0 0 5px', textTransform: "capitalize" }}>{locale === "en" ? product?.category.Name_en : product?.category.Name_ar}</h2>
                  <h2 className="product-name" style={{ fontSize: "22px", fontWeight: "bold", margin: '0' }}>{locale === "en" ? product?.Name_en : product?.Name_ar}</h2>
                </div>
                <h4><span className="spant"><FormatPrice price={product?.price} sale_price={product?.sale_price} /></span></h4>
              </Stack>
              <span className="rating-container" style={{ fontSize: '15px', fontWeight: 500, color: '#9E9E9E', display: 'flex', alignItems: 'center' }}><Rating className="rating" sx={{ fontSize: '19px' }} name="read-only" value={4.5} precision={0.1} readOnly />(4.5)</span>
              <h2 className="description">{t('Description')}</h2>
              <div className="desc" dangerouslySetInnerHTML={{ __html: locale === "en" ? product?.Description_en : product?.Description_ar }} />
              <div className='available'>Available:</div>
              <span className="stock-available">{product?.stock > 0 ? 'In Stock' : 'Out of Stock'}</span>

              <div className="desktop">
                {product?.stock > 0 && <AddToCart product={product} />}
              </div>

              <div className='mobile1' style={{backgroundColor: theme.palette.mode === 'dark' ? '#0f0f0f' : '#f1f4fb'}}>
                <div className='add-to-cart-mobile'>
                  {product?.stock > 0 && <AddToCart product={product} />}
                </div>
              </div>

            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
