import React from 'react'
import { LocalContext } from '../context/LocalContext';
import { useContext } from 'react';
import '../css/firstsec.css'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Box, Stack } from '@mui/material';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import Grid from '@mui/material/Unstable_Grid2';

export default function FirstSec({ settings }) {
  const [t, i18n] = useTranslation("global");
  const { locale, setLocale } = useContext(LocalContext);
  const navigate = useNavigate();

  function goingto() {
    navigate('/shipping');
}

  return (
    <div dir={locale === "en" ? "ltr" : "rtl"} className={[locale === "en" ? "ltr" : "rtl", "firstSec"].join(" ")}>

      <Stack direction="row" alignItems="center" spacing={2} sx={{ justifyContent: 'flex-start', }}>
        <Box sx={{ maxWidth: 100 }}>
          <img
            style={{ height: 100, borderRadius: '5px' }}
            src={settings[0]?.value}
            alt="Company logo"
          />
        </Box>
        <div style={{ textAlign: locale === "en" ? 'left' : 'right', marginLeft: '20px' }}>
          <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{settings[1]?.value}</div>
          <div style={{ fontSize: '14px', color: '#777', fontWeight: 400, margin: '5px 0' }}>{locale === "en" ? settings[8]?.value : settings[2]?.value}</div>
          <img style={{ maxWidth: '240px' }} src={require('../assets/commercial-ezgif.com-jpg-to-png-converter.jpg')} alt="" />
        </div>
      </Stack>

      <Grid container spacing={2} sx={{ paddingTop: '12px', marginBottom: '10px', alignItems: 'center' }}>
        <Grid xs={1}>
          <TwoWheelerIcon sx={{ color: '#999', width: '21px', height: '21px' }} />
        </Grid>
        <Grid xs={1}>
          <p className='deliver-to' style={{ color: '#999', fontSize: '14px', fontWeight: '400', textAlign: 'left', textWrap: 'nowrap' }}>{t('Deliver to')}</p>
        </Grid>
        <Grid xs={10}>
          <p className='location' onClick={goingto} style={{ fontSize: '14px', textAlign: 'right', cursor: 'pointer', paddingRight: '20px' }}>{localStorage.getItem('selectedCity') ? localStorage.getItem('selectedCity') : t('Select Your Location')}</p>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid xs={1}>
          <WatchLaterOutlinedIcon sx={{ color: '#999', width: '21px', height: '21px' }} />
        </Grid>
        <Grid xs={1}>
          <p className='duration' style={{ color: '#999', fontSize: '14px', fontWeight: '400', textAlign: 'left', textWrap: 'nowrap' }}>{t('Duration')}</p>
        </Grid>
        <Grid xs={10}>
          <p className='time' style={{ fontSize: '14px', textAlign: 'right', paddingRight: '20px' }}>{locale === "en" ? settings[5]?.value : settings[4]?.value}</p>
        </Grid>
      </Grid>

    </div>
  )
}
