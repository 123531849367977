import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalContext } from '../context/LocalContext'

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function Form({ personalInfo, handleForm }) {
    const { locale, setLocale } = useContext(LocalContext);
    const { t, i18n } = useTranslation();

    return (
        <div className={[locale === 'en' ? 'ltr' : 'rtl', 'checkout-form'].join(' ')} style={{ marginTop: '20px' }}>
            <form className='form'>
                <TextField required fullWidth value={personalInfo.FullName} onChange={handleForm} size="small" name='FullName' label={t("FullName")} variant="outlined" />
                <TextField required fullWidth value={personalInfo.Phone} onChange={handleForm} size="small" name='Phone' label={t("Phone")} variant="outlined" />
                <TextField fullWidth value={personalInfo.Email} onChange={handleForm} size="small" name='Email' label={t("Email")} variant="outlined" />
                <FormControl fullWidth required sx={{ minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">{t("Select Country")}</InputLabel>
                    <Select
                        className={[locale === 'en' ? 'ltr' : 'rtl', 'checkout-select'].join(' ')}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name='Country'
                        value={personalInfo.Country}
                        label={t("Select Country")}
                        onChange={handleForm}
                    >
                        <MenuItem value="Emirates">{t('Emirates')}</MenuItem>
                        <MenuItem value="Bahrain">{t("Bahrain")}</MenuItem>
                        <MenuItem value="Saudi Arabia">{t("Saudi Arabia")}</MenuItem>
                        <MenuItem value="Oman">{t('Oman')}</MenuItem>
                        <MenuItem value="Kuwait">{t("Kuwait")}</MenuItem>
                        <MenuItem value="Qatar">{t("Qatar")}</MenuItem>
                    </Select>
                </FormControl>

                <TextField required fullWidth value={personalInfo.Block} onChange={handleForm} size="small" name='Block' label={t("Block")} variant="outlined" />
                <TextField required fullWidth value={personalInfo.Street} onChange={handleForm} size="small" name='Street' label={t("Street")} variant="outlined" />
                <TextField fullWidth value={personalInfo.Avenue} onChange={handleForm} size="small" name='Avenue' label={t("Avenue")} variant="outlined" />
                <TextField required fullWidth value={personalInfo.House} onChange={handleForm} size="small" name='House' label={t("House")} variant="outlined" />
                <TextField fullWidth value={personalInfo.FloorApartment} onChange={handleForm} size="small" name='FloorApartment' label={t("Floor Apartment")} variant="outlined" />
                <TextField fullWidth value={personalInfo.Notes} onChange={handleForm} size="small" name='Notes' label={t("Notes")} multiline rows={4} />
            </form>
        </div>
    )
}
