import React from 'react'
import { LocalContext } from '../context/LocalContext'
import { useTranslation } from 'react-i18next';
import { useState, useContext } from 'react';
import '../css/shipping.css'
import SearchFilter from '../components/SearchFilter'

import { Container } from '@mui/material'
import { useTheme } from '@mui/material/styles';

export default function Shipping() {
  const [t, i18n] = useTranslation("global");
  const { locale, setLocale } = useContext(LocalContext);
  const theme = useTheme();

  return (
    <div dir={locale === "en" ? "ltr" : "rtl"} className={[locale === "en" ? "ltr" : "rtl", 'shipping-page'].join(' ')}>
      <Container maxWidth="lg">
        <div className='shipping' style={{padding: '5px', display: 'flex', alignItems: 'center', margin: '20px 0', fontSize: '16px', color: 'black', backgroundColor: '#eee', borderRadius: '5px' }}>
          <div className='location-header'>
            {t('Select Your Location')}
          </div>
        </div>
        <div className='delivery-container' style={{ width: 'fit-content', margin: '0 auto' }}>
          <div className='delivery' style={{ textAlign: 'center', fontSize: '16px', color: theme.palette.mode === 'dark' ? 'white' : 'black', padding: '10px', fontWeight: 'bold', borderBottom: '2px solid black' }}>{t('Delivery')}</div>
        </div>
        <hr style={{ border: '1px solid #eee', margin: '0' }} />
        <div style={{ margin: '20px 0' }}>
          <SearchFilter />
        </div>
      </Container>
    </div>
  )
}
