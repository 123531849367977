import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalContext } from '../context/LocalContext'

import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import WorkIcon from '@mui/icons-material/Work';
import CheckIcon from '@mui/icons-material/Check';

export default function RadioBtns({ personalInfo, setPersonalInfo }) {
    const { locale, setLocale } = useContext(LocalContext);
    const { t, i18n } = useTranslation();

    return (
        <div className='radioButtons' style={{ marginBottom: '20px' }} >
            <label className='customRadio'>
                <input type="radio" name="delivery" value="House" checked={personalInfo.DeliveryType === 'House'} onChange={(e) => {
                    setPersonalInfo({ ...personalInfo, DeliveryType: e.target.value })
                }} />
                <span className='radioButton'><CheckIcon className='icon' />
                    <div className='deliveryicon'>
                        <HomeIcon className='homeicon' />
                        <h3>{t('House')}</h3>
                    </div>
                </span>
            </label>
            <label className='customRadio'>
                <input type="radio" name="delivery" value="Apartment" checked={personalInfo.DeliveryType === 'Apartment'} onChange={(e) => {
                    setPersonalInfo({ ...personalInfo, DeliveryType: e.target.value })
                }} />
                <span className='radioButton'><CheckIcon className='icon' />
                    <div className='deliveryicon'>
                        <ApartmentIcon className='homeicon' />
                        <h3>{t('Apartment')}</h3>
                    </div>
                </span>
            </label>
            <label className='customRadio'>
                <input type="radio" name="delivery" value="Work" checked={personalInfo.DeliveryType === 'Work'} onChange={(e) => {
                    setPersonalInfo({ ...personalInfo, DeliveryType: e.target.value })
                }} />
                <span className='radioButton'><CheckIcon className='icon' />
                    <div className='deliveryicon'>
                        <WorkIcon className='homeicon' />
                        <h3>{t('Work')}</h3>
                    </div>
                </span>
            </label>
        </div>
    )
}
