import { useCheckOut } from "../context/CheckOutProvider"
import { useCart } from "../context/CartProvider";
import axios from "axios";
import { baseUrl, ORDERS } from "../api/Api";
import ToastContext from '../context/ToastProvider'
import { useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';

function CheckOutFn() {
    const { personalInfo, setPersonalInfo } = useCheckOut();
    const { cart, clearCart, total_price, shipping_fee } = useCart()
    const { t, i18n } = useTranslation();
    const { showHideToast } = useContext(ToastContext);

    // console.log('cart', cart)

    // ========== to store data before unloading start ==========
    useEffect(() => {
        const storedData = localStorage.getItem('checkoutFormData');
        if (storedData) {
            setPersonalInfo(JSON.parse(storedData));
        }

        const handleBeforeUnload = () => {
            localStorage.removeItem('checkoutFormData');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    // ========== to store data before unloading end ==========

    // useEffect to save data to localStorage before unloading
    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.setItem('checkoutFormData', JSON.stringify(personalInfo));
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    });
    // useEffect to save data to localStorage before unloading

    // ========== send data to backend start ==========
    async function handleCheckout(e) {
        e.preventDefault();

        const selectedCity = localStorage.getItem('selectedCity') || 'No city selected';
        const products = cart.map(item => ({ id: item.id, quantity: item.amount }));

        const params = {
            status: 'processing',
            avenue: personalInfo.Avenue,
            block: personalInfo.Block,
            delivery_type: personalInfo.DeliveryType,
            floor_apartment: personalInfo.FloorApartment,
            house: personalInfo.House,
            state: selectedCity,
            street: personalInfo.Street,
            total_order_price: total_price,
            products: products,
            country: personalInfo.Country,
            additional_notes: personalInfo.Notes,
            shipping_cost: shipping_fee,
            discount: 2,
            full_name: personalInfo.FullName,
            phone: personalInfo.Phone,
            email: personalInfo.Email,
        }
        // console.log("params", params)
        try {
            const response = await axios.post(`${baseUrl}${ORDERS}`, params, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            console.log(response)
            showHideToast(t("Added successfully"));
            clearCart();
            setPersonalInfo({
                Avenue: '',
                Block: '',
                DeliveryType: '',
                FloorApartment: '',
                House: '',
                State: '',
                Street: '',
                TotalOrderPrice: '',
                Notes: '',
                ShippingCost: '',
                Discount: '',
                FullName: '',
                Phone: '',
                Email: '',
                Country: '',
            })
            localStorage.removeItem('checkoutFormData');

        } catch (error) {
            console.log(error)
        }
    }
    // ========== send data to backend end ==========
    return { handleCheckout }
}

export default CheckOutFn