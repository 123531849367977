import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalContext } from '../context/LocalContext'
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';

export default function DeliveryTo() {
    const { locale, setLocale } = useContext(LocalContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    function goingto() {
        navigate('/shipping');
    }

    return (
        <div>
            <Grid container spacing={2} sx={{ alignItems: 'center', marginTop: '5px' }}>
                <Grid xs={1}>
                    <TwoWheelerIcon sx={{ color: '#999', width: '21px', height: '21px' }} />
                </Grid>
                <Grid xs={1}>
                    <p style={{ color: '#999', fontSize: '14px', fontWeight: '400', textAlign: 'left', textWrap: 'nowrap' }}>{t("Deliver to")}</p>
                </Grid>
                <Grid sx={{ flexGrow: 1 }} xs={10}>
                    <p className='duration' onClick={goingto} style={{ fontSize: '14px', textAlign: 'right', cursor: 'pointer', paddingRight: '20px', fontWeight: 700 }}>{localStorage.getItem('selectedCity') ? localStorage.getItem('selectedCity') : t('Select Your Location')}</p>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ alignItems: 'center', marginBottom: '10px' }}>
                <Grid xs={1}>
                    <WatchLaterOutlinedIcon sx={{ color: '#999', width: '21px', height: '21px' }} />
                </Grid>
                <Grid xs={1}>
                    <p style={{ color: '#999', fontSize: '14px', fontWeight: '400', textAlign: 'left', textWrap: 'nowrap' }}>{t("Duration")}</p>
                </Grid>
                <Grid sx={{ flexGrow: 1 }} xs={10}>
                    <p className='duration' style={{ fontSize: '14px', textAlign: 'right', paddingRight: '20px', fontWeight: 700 }}>{t('3 to 5 days')}</p>
                </Grid>
            </Grid>
        </div>
    )
}
