import { useState } from 'react'
import '../css/searchfilter.css'
import { useTranslation } from 'react-i18next';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SearchFilter = () => {
  const [t, i18n] = useTranslation("global");
  const [searchTerm, setSearchTerm] = useState('');
  const [expanded, setExpanded] = useState({});
  const theme = useTheme();

  const goBack = () => {
    window.history.back(); // This function navigates to the previous page
  };

  const governments = [
    {
      name: 'محافظة العاصمة',
      cities: [
        'الخالدية', 'الدسمة', 'الدعية', 'الدوحة', 'الروضة', 'الري', 'السرة',
        'الشامية', 'الشرق', 'الشويخ', 'الصالحية', 'الصليبخات', 'الصوابر',
        'العديلية', 'الفيحاء', 'القادسية', 'القبلة', 'القيروان', 'المرقاب',
        'المنصورية', 'النزهة', 'النهضة', 'الوطية', 'اليرموك', 'بنيد القار',
        'دسمان', 'شمال غرب الصليبيخات', 'ضاحية عبد الله السالم', 'غرناطة',
        'قرطبة', 'كيفان', 'مدينة الكويت', 'مدينة جابر الأحمد'
      ]
    },
    {
      name: 'محافظة الأحمدي',
      cities: [
        'أبو حليفة', 'الأحمدي', 'الجليعة', 'الخيران', 'الرقة', 'الزور',
        'الشعيبة', 'الصباحية', 'الضباعية', 'الظهر', 'العقيلة', 'الفحيحيل',
        'الفنطاس', 'المقوع', 'المنقف', 'المهبولة', 'النويصيب', 'الوفرة',
        'الوفرة الزراعية', 'أم الهيمان', 'بنيدر', 'ضاحية جابر العلي',
        'ضاحية علي صباح السالم', 'ضاحية فهد الأحمد', 'مدينة الخيران',
        'مدينة صباح الأحمد', 'مدينة صباح الأحمد البحرية', 'ميناء عبد الله',
        'هدية', 'واره'
      ]
    },
    {
      name: 'محافظة الجهراء',
      cities: [
        'الجهراء الجديدة', 'الجهراء القديمة', 'الروضتين', 'السالمي', 'الصبية',
        'الصليبية', 'العبدلي', 'العيون', 'القصر', 'القيصرية', 'المطلاع',
        'النسيم', 'النعيم', 'الواحة', 'أمغرة', 'تيماء', 'كاظمة', 'كبد',
        'مدينة الحرير', 'مدينة سعد العبد الله'
      ]
    },
    {
      name: 'محافظة الفروانية',
      cities: [
        'أبرق خيطان', 'إشبيلية', 'الأندلس', 'الحساوي', 'الخيطان',
        'الخيطان الجديدة', 'الرابية', 'الرحاب', 'الرقعي', 'الري الصناعية',
        'الشدادية', 'الضجيج', 'العارضية', 'العارضية الصناعية', 'العمرية',
        'الفردوس', 'الفروانية', 'جليب الشيوخ', 'ضاحية صباح الناصر',
        'ضاحية عبد الله المبارك'
      ]
    },
    {
      name: 'محافظة حولي',
      cities: [
        'البدع', 'الجابرية', 'الرميثية', 'الزهراء', 'السالمية', 'السلام',
        'الشعب', 'الشهداء', 'الصديق', 'النقرة', 'بيان', 'جنوب السرة',
        'حطين', 'سلوى', 'ضاحية مبارك العبد الله الجابر', 'مشرف',
        'منطقة حولي', 'ميدان حولي'
      ]
    },
    {
      name: 'محافظة مبارك الكبير',
      cities: [
        'القرين', 'ضاحية مبارك الكبير', 'العدان', 'القصور',
        'ضاحية صباح السالم', 'المسيلة', 'صبحان', 'الفنيطيس',
        'أبو فطيرة', 'المسايل', 'أبو الحصانية'
      ]
    }
  ];

  const filterCities = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const toggleExpansion = (governmentIndex) => {
    setExpanded((prevState) => ({
      ...prevState,
      [governmentIndex]: !prevState[governmentIndex]
    }));
  };

  const handleCityClick = (city) => {
    localStorage.setItem('selectedCity', city);
    goBack();
  };

  const renderGovernments = () => {
    return (
      <div>
        {governments.map((government, index) => {
          const filteredCities = government.cities.filter(city => city.toLowerCase().includes(searchTerm));
          if (filteredCities.length === 0) return null; // Skip rendering if no matching cities
          return (
            <div key={index} className="list">
              <h3 className="accordion-header" onClick={() => toggleExpansion(index)}>
                {government.name}
                {expanded[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </h3>
              {expanded[index] && (
                <div>
                  {filteredCities.map((city, cityIndex) => (
                    <div key={cityIndex} style={{ backgroundColor: theme.palette.mode === 'dark' ? '#f0f0f0' : 'white' }} className="list-item" onClick={() => handleCityClick(city)}>
                      {city}
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <Stack className="searchBar-container" direction="row" alignItems={'center'} sx={{ bgcolor: theme.palette.mode === 'dark' ? '#f0f0f0' : 'white', mb: '20px', borderRadius: '10px' }}>
        <SearchIcon sx={{ bgcolor: 'black', color: 'white', borderRadius: '10px', p: 1, height: '30px', width: '30px' }} />
        <input
          className='searchBar'
          style={{ backgroundColor: theme.palette.mode === 'dark' ? '#f0f0f0' : 'white', color: theme.palette.mode === 'dark' ? 'white' : 'black' }}
          type="text"
          placeholder={t("Search for Cities and Areas")}
          value={searchTerm}
          onChange={filterCities}
        />
      </Stack>
      <div id="listsContainer" className="listsContainer">
        {renderGovernments()}
      </div>
    </div>
  );
};

export default SearchFilter;
