import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { LocalContext } from './context/LocalContext';
import { useState } from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import global_en from './translations/en/global.json';
import global_ar from './translations/ar/global.json';
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ToastProvider } from './context/ToastProvider';

import Home from './pages/Home';
import Shipping from './pages/Shipping';
import Products from './pages/Products';
import OneProduct from './pages/OneProduct';
import Cart from './pages/Cart';
import Thanks from './pages/Thanks';
import Header from './components/Header';


function App() {
  const [locale, setLocale] = useState("en");
  const [theme, colorMode] = useMode();

  i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
      en: { global: global_en },
      ar: { global: global_ar }
    }
  });

  // direction right and left
  const rtlCache = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });
  const ltrCache = createCache({
    key: 'mui',
  });

  return (
    <div className='App'>
    <CacheProvider value={locale === "ar" ? rtlCache : ltrCache}>
      <LocalContext.Provider value={{ locale, setLocale }}>
        <I18nextProvider i18n={i18next}>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ToastProvider>
                <BrowserRouter>
                  <Header />
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/shipping" element={<Shipping />} />
                    <Route path='/products' element={<Products />} />
                    <Route path='/product/:id' element={<OneProduct />} />
                    <Route path='/cart' element={<Cart />} />
                    <Route path='/thanks' element={<Thanks />} />
                  </Routes>
                </BrowserRouter>
              </ToastProvider>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </I18nextProvider>
      </LocalContext.Provider>
    </CacheProvider>
    </div>
  );
}

export default App;
