import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ProductProvider from './context/ProductProvider';
import SettingsProvider from './context/SettingsProvider';
import FilterProvider from './context/FilterProvider';
import CartProvider from './context/CartProvider';
import CheckOutProvider from './context/CheckOutProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ProductProvider>
    <FilterProvider>
      <CartProvider>
        <SettingsProvider>
          <CheckOutProvider>
            <App />
          </CheckOutProvider>
        </SettingsProvider>
      </CartProvider>
    </FilterProvider>
  </ProductProvider>
);

reportWebVitals();

{/* <React.StrictMode>
</React.StrictMode> */}