import { useState, useContext, useEffect } from 'react';
import '../css/checkout.css'
import axios from 'axios';
import ToastContext from '../context/ToastProvider'
import { useTranslation } from 'react-i18next';
import { LocalContext } from '../context/LocalContext'
import Form from './Form';
import DeliveryTo from './DeliveryTo';
import RadioBtns from './RadioBtns';

export default function CheckOut({ personalInfo, setPersonalInfo, handleForm }) {
    const { locale, setLocale } = useContext(LocalContext);
    const { t, i18n } = useTranslation();
    const { showHideToast } = useContext(ToastContext);


    return (
        <div dir={locale === "en" ? "ltr" : "rtl"} className={[locale === "en" ? "ltr" : "rtl", 'checkout-page'].join(' ')}>
            <div>
                <div style={{ width: 'fit-content' }}>
                    <h3 style={{ textAlign: 'left', margin: '20px 0 0 0', paddingBottom: '10px', borderBottom: '1px solid black', fontWeight: 'bold' }}>{t('Billing Details')}</h3>
                </div>
                <hr style={{ border: '1px solid #eee', margin: '0 0 0 0' }} />
            </div>
            <DeliveryTo />
            <RadioBtns personalInfo={personalInfo} setPersonalInfo={setPersonalInfo}/>
            <Form personalInfo={personalInfo} handleForm={handleForm}/>
        </div>
    )
}
